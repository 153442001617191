import React, { Component } from 'react';
import {GatsbyImage} from "gatsby-plugin-image";
import {Link} from "gatsby";

class MarketingGrid extends Component {
    render() {
        console.log(this.props.props.marketing);
        return(
            <div className="container">
                <div className="row">
                    {
                        this.props.props.marketing.edges.slice().map(({node: marketing, index}) => {
                            return (
                                <div className="col-sm-4 iberica-grid-produto-col" key={marketing.id}>
                                    <div className="card iberica-grid-produto-card">
                                        <div className="card-img-top" >
                                            {
                                                marketing.comportaVideo ? (<video controls={true} width="100%">
                                                    <source src={marketing.videoMaterial.file.url}
                                                            type={marketing.videoMaterial.file.contentType}/>
                                                </video>) : ( marketing.imagemMaterial !== null ? <GatsbyImage alt={marketing.tipoMaterial} style={{maxHeight: "150px"}} image={marketing.imagemMaterial.gatsbyImageData} /> : (marketing.comportaPdf ? <a className="btn btn-primary mt-3 mb-3" style={{marginLeft: "20px"}} href={marketing.pdFs[0].file.url} target="_blank" rel="noopener,noreferrer">Abrir PDF</a> : <></>))
                                            }

                                        </div>
                                        <div className="card-body">
                                            <h2 className="card-title">{marketing.tipoMaterial}</h2>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        )
    }
}

export default MarketingGrid