import React from 'react';
import {graphql} from "gatsby";
import Header from "../components/Header";
import Footer from "../components/Footer";
import HeroVideo from "../components/GeneralComponents/HeroVideo";
import {Helmet} from "react-helmet";
import MarketingGrid from "../components/Marketing/MarketingGrid";

const Marketing = ({data}) => {
    return (
        <>
            <Helmet>
                <html lang="pt-BR" amp />
                <meta charSet="UTF-8" />
                <title>Cia. Ibérica - Marketing</title>
                <link rel="canonical" href="https://www.ciaiberica.com.br/marketing/" />
                <meta name="robots" content="index,follow,max-snippet: -1,max-image-preview:large" />
                <meta property="og:locale" content="pt-BR" />
                <meta property="og:title" content="Cia. Ibérica - Marketing" />
                <meta property="og:description" content="Confira as novidades em marketing da Cia. Ibérica, empresa brasileira e com tradição no mercado." />
                <meta property="og:url" content="https://www.ciaiberica.com.br/marketing/" />
                <meta property="og:sitename" content="Cia. Ibérica" />
                <meta property="article:publisher" content="https://facebook.com/ibericacondutoreseletricosltda" />
                <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
            </Helmet>
            <Header/>
            <HeroVideo props={data} />
            <div className="container mt-3 mb-3 text-center">
                <h2>Marketing</h2>
                <h5>Nesta página, você encontra diversos materiais de Marketing da Cia. Ibérica. Confira!</h5>
            </div>
            <MarketingGrid props={data} />
            <Footer/>
        </>
    )
}

export const query = graphql`
    query MarketingQuery {
        marketing: allContentfulMateriaisDeMarketing(filter: {node_locale: {eq: "en-US"}}) {
            edges {
                node {
                    id
                    tipoMaterial
                    comportaImagem
                    imagemMaterial {
                        gatsbyImageData
                    }
                    comportaVideo
                    comportaPdf
                    pdFs {
                        file {
                            contentType
                            url
                        }
                    }
                    videoMaterial {
                        file {
                            url
                            contentType
                        }
                    }
                }
            }
        }
        video: contentfulAsset(title: {eq: "marketing"}) {
            file {
              url
              contentType
            }
        }
        poster: contentfulAsset(title: {eq: "marketing-poster"}) {
            file {
              url
              contentType
            }
        }
    }
`
export default Marketing